const data = [
  {
    id: 1,
    image: "https://img.icons8.com/?size=100&id=3708&format=png&color=000000",
    title: "Azure Data Factory",
   
  }, {
    id: 1,
    image: "https://img.icons8.com/?size=100&id=fq2Fe7yQCKjH&format=png&color=000000",
    title: "Synapse Analytics",
   
  }, {
    id: 1,
    image: "https://avatars.githubusercontent.com/u/4998052?s=200&v=4",
    title: "Databricks",
   
  }, {
    id: 1,
    image: "https://securiti.ai/wp-content/uploads/2023/08/Azure-Data-Lake-Gen2-Logo-Large.svg",
    title: "Data Lake",
   
  }, {
    id: 1,
    image: "https://azure.microsoft.com/svghandler/microsoft-fabric/?width=600&height=315",
    title: "Azure Microsoft Fabric",
   
  }, {
    id: 1,
    image: "https://img.icons8.com/?size=100&id=70667&format=png&color=000000",
    title: "Power BI",
   
  }, {
    id: 1,
    image: "https://logowik.com/content/uploads/images/azure-cosmos-db7049.jpg",
    title: "Azure Cosmos DB",
   
  }, {
    id: 1,
    image: "https://img.icons8.com/fluency/96/null/javascript.png",
    title: "JavaScript",
   
  },
  {
    id: 2,
    image: "https://img.icons8.com/plasticine/100/null/react.png",
    title: "React.js",
   
  },
  {
    id: 3,
    image: "https://upload.wikimedia.org/wikipedia/commons/8/8e/Nextjs-logo.svg ",
    title: "Next.js",
   
  },
  {
    id: 4,
    image: "https://img.icons8.com/fluency/96/null/typescript--v1.png ",
    title: "TypeScript",
   
  },
  {
    id: 5,
    image: "https://avatars.githubusercontent.com/u/70142?s=200&v=4 ",
    title: "jQuery",
    
  },
  {
    id: 6,
    image: "https://img.icons8.com/color/144/null/nodejs.png ",
    title: "Node.js",
   
  },
  {
    id: 7,
    image: "https://img.icons8.com/officel/160/null/php-logo.png ",
    title: "PHP",
   
  },
  {
    id: 8,
    image: "https://upload.wikimedia.org/wikipedia/commons/9/9a/Laravel.svg ",
    title: "Laravel",
  
  },
  {
    id: 9,
    image: "https://img.icons8.com/color/144/null/mysql-logo.png ",
    title: "MySQL",
  
  },
  {
    id: 10,
    image: "https://img.icons8.com/ios/100/000000/postgreesql.png ",
    title: "PostgreSQL",
  
  },
  {
    id: 11,
    image: "https://img.icons8.com/color/96/null/bootstrap.png ",
    title: "Bootstrap",
   
  },
  {
    id: 12,
    image: "https://img.icons8.com/color/96/null/tailwindcss.png ",
    title: "Tailwindcss",
  
  },
  {
    id: 13,
    image: "https://seeklogo.com/images/P/postman-logo-F43375A2EB-seeklogo.com.png ",
    title: "Postman",
  
  },
  {
    id: 14,
    image: "https://img.icons8.com/color/96/null/git.png ",
    title: "Git",
   
  },
  {
    id: 15,
    image: "https://img.icons8.com/?size=100&id=13441&format=png&color=000000 ",
    title: "Python",
  },
  {
    id: 16,
    image: "https://img.icons8.com/ios-filled/50/null/linux.png",
    title: "Linux",
    },
  {
    id: 17,
    image: "https://img.icons8.com/?size=100&id=bosfpvRzNOG8&format=png&color=000000.png",
    title: "Mongo Db",
  },
  {
    id: 17,
    image: "https://img.icons8.com/?size=100&id=laYYF3dV0Iew&format=png&color=000000",
    title: "Microsoft SQL Server",
  }
]
export default data
